.menus,
.menu-popup,
.os-window,
.os-window .window-titlebar,
.os-window .window-title {
	cursor: default;
	user-select: none;
}

.os-window .window-titlebar,
body > .window-titlebar {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.os-window .window-title-area {
	position: relative;
	flex: 1;
}

.os-window .window-titlebar .icon {
	vertical-align: bottom;
}

/* Fix dragging things (like windows) over iframes */
.dragging iframe {
	pointer-events: none;
}

.menus {
	display: flex;
	flex: 0 0 auto;
}
.menu-container {
	position: relative;
}
.menu-popup {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 400000; /* so sub-menu-popups can be visible over the window */
	box-sizing: border-box;
}
.menu-popup-table {
	border-collapse: collapse;
}
.menu-item {
	white-space: nowrap;
}
.menu-hr {
	display: block !important;
	height: 0;
	width: auto;
}
.menu-hotkey {
	display: inline !important;
}
.menu-item-checkbox-area,
.menu-item-submenu-area {
	min-width: 16px;
}
.menu-item-checkbox-area,
.menu-item-submenu-area {
	text-align: center;
}

/* .window-content .button-group {
	width: 85px;
}
.window-content .button-group > button {
	width: 95%;
	padding: 3px 5px;
} */

::before,
::after {
	pointer-events: none;
}
